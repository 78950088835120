import React, { useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/quilmesTourStyles/reservaStep1.module.css"
import { Select, DatePicker, Space } from "antd"
import calendarIcon from "../../images/calendar-gray.svg"
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons"
import moment from "moment"
import "antd/dist/antd.css"
import "../../styles/quilmesTourStyles/index.css"

const { Option } = Select

const ReservaSelects = ({
  fecha,
  tour,
  setPersonas,
  setFecha,
  setTour,
  personas,
  onDateChange,
  onTourChange,
  tourOptions,
  dateOptionsOfCurrentTour,
  counterUp,
  counterDown,
  addPointsToNumber,
}) => {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })

  function disabledDate(current) {
    return (
      current < moment().endOf("day") ||
      !dateOptionsOfCurrentTour.some(
        date => date.fecha === current.format("YYYY-MM-DD")
      )
    )
  }

  function cleanAllData() {
    setPersonas(1)
    setFecha("")
    setTour({ name: null, id: null })
  }

  return (
    <div className={styles.selectsContainer}>
      <div className={styles.selectTitleContainer}>
        <h1 className={styles.selectTitle}>Elegí una fecha y el horario.</h1>
      </div>
      <div className={styles.select}>
        <div>
          <Select
            size="medium"
            placeholder="TOUR"
            style={{
              width: mobile ? "350px" : "200px",
              marginRight: "10px",
              // height: mobile ? "50px" : "4vh",
              // fontSize: mobile ? "2vw" : "2vh",
            }}
            className="select1"
            allowClear={true}
            value={tour.name ? tour.name : null}
            onChange={onTourChange}
          >
            {tourOptions.map((tour, index) => (
              <Option
                key={index}
                value={JSON.stringify({
                  name: tour.tourdescrip,
                  id: tour.idTour,
                  precio: tour.precio,
                  // paymentLink1: tour.paymentLink1,
                  // paymentLink2: tour.paymentLink2,
                  // paymentLink3: tour.paymentLink3,
                  // paymentLink4: tour.paymentLink4,
                  // paymentLink5: tour.paymentLink5,
                })}
                style={{
                  borderRadius: mobile ? "0px !important" : "auto",
                }}
              >
                {tour.tourdescrip}
              </Option>
            ))}
          </Select>
        </div>

        <div>
          <Space direction="vertical" className="select1">
            <DatePicker
              value={fecha ? moment(fecha) : null}
              disabledDate={disabledDate}
              disabled={!tour.id}
              size="medium"
              style={{
                borderRadius: mobile ? "0px" : "40px",
                padding: "6px 12px",
                marginRight: "10px",
                border: "none",
                backgroundColor: "rgba(15, 145, 215, 0.06)",
                width: mobile ? "350px" : "140px",
                fontSize: mobile ? "2vw" : "2vh",
                // marginRight: !mobile && "10px",
                // marginLeft: !mobile && "-25px",
              }}
              onChange={onDateChange}
              suffixIcon={
                <img
                  src={calendarIcon}
                  style={{
                    height: "15px",
                    marginBottom: "1px",
                  }}
                ></img>
              }
            />
          </Space>
        </div>
        <div>
          <div className={styles.personasSelect}>
            <MinusCircleOutlined onClick={counterDown} />
            <h1 className={styles.pText}>
              {personas} PERSONA{personas > 1 && "S"}
            </h1>
            <PlusCircleOutlined onClick={counterUp} />
          </div>
        </div>
        <div>
          <div className={styles.price}>
            Total: ${tour && addPointsToNumber(tour.precio * personas)}
          </div>
        </div>
        <div>
          <div className={styles.cleanContainer} onClick={cleanAllData}>
            <h1 className={styles.cleanText}>Limpiar búsqueda</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReservaSelects
