import React, { useState, Fragment, useEffect } from "react"
import { Router, redirectTo } from "@reach/router"
import { navigate } from "gatsby"
import { cleanImagesArray } from "../../utils/images"
import SEO from "../components/seo"
import QuilmesTourIndex from "../components/lacasadequilmes"
import ReservaStep1 from "../components/lacasadequilmes/reservaStep1"
import ReservaStep2 from "../components/lacasadequilmes/reservaStep2"
import ReservaStep3 from "../components/lacasadequilmes/reservaStep3"
import LayoutTour from "../components/lacasadequilmes/layoutQuilmesTour"
import TourMobileDescription from "../components/lacasadequilmes/tourMobileDescription"

const LaCasaDeQuilmes = ({ data, location }) => {
  const [images, setImages] = useState(false)
  const [fecha, setFecha] = useState("")
  const [tour, setTour] = useState({ name: null, id: null })
  const [personas, setPersonas] = useState(1)
  const [questions, setQuestions] = useState([])
  const [precauciones, setPrecauciones] = useState([])
  const [comollego, setComollego] = useState([])
  const [bases, setBases] = useState({})
  const [tourOptions, setTourOptions] = useState([])
  const [dateOptions, setDateOptions] = useState([])
  const [dateOptionsOfCurrentTour, setDateOptionsOfCurrentTour] = useState([])
  const [availability, setAvailability] = useState(0)
  const [selectedTime, setSelectedTime] = useState({})

  const dataFaq = data.allFaq.nodes
  const dataComollego = data.allComollego.nodes
  const dataPrecauciones = data.allPrecauciones.nodes
  const dataBases = data.allBases.nodes[0]
  useEffect(() => {
    setQuestions(dataFaq)
    setComollego(dataComollego)
    setPrecauciones(dataPrecauciones)
    setBases(dataBases)
    if (tour.id)
      setDateOptionsOfCurrentTour(
        dateOptions.find(array => {
          if (array.length) {
            return array[0].idTour === tour.id
          }
        })
      )
  }, [tour])

  useEffect(() => {
    window.dataLayer.push({
      brand: "Quilmes",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "La Casa de Quilmes",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.quilmes.com.ar/lacasadequilmes",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })
  }, [])

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!session && !local)
      navigate("/agegate", { state: { newPath: "/lacasadequilmes" } })
    setImages(cleanImagesArray(data))
  }, [])

  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"
  // const api = "http://localhost:8080/api"

  useEffect(() => {
    fetch(`${api}/tour/tours/active`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async response => {
      if (response.ok) {
        let data = await response.json()
        // console.log("TOOURS  ===>", data)
        setTourOptions(data)
      } else {
        console.error("Error cargando los datos. \n", response)
      }
    })
  }, [])

  useEffect(() => {
    if (tourOptions.length)
      fetch(`${api}/tour/calendario/active`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(async response => {
        if (response.ok) {
          let data = await response.json()
          let newArray = tourOptions.map(tour =>
            data.filter(date => date.idTour === tour.idTour)
          )
          // console.log("CALENDARRIOOOO  ===>", data)
          setDateOptions(newArray)
        } else {
          console.error("Error cargando los datos. \n", response)
        }
      })
  }, [tourOptions])

  useEffect(() => {
    if (fecha && !tour.preventDateRefresh) setFecha("")
  }, [tour])

  function onDateChange(date, dateString) {
    setFecha(dateString)
    let availableDates = dateOptionsOfCurrentTour.filter(
      date => date.fecha === dateString
    )
    if (availableDates.length) {
      let availabilityArray = availableDates.map(date => date.disponibilidad)
      let maximumAvailability = Math.max(...availabilityArray)
      setAvailability(maximumAvailability)
    } else {
      setAvailability(0)
    }
  }

  function counterUp() {
    if (personas < 5) {
      if (selectedTime.idFecha) {
        if (personas < selectedTime.disponibilidad) setPersonas(personas + 1)
      } else {
        if (personas < availability) setPersonas(personas + 1)
      }
    }
  }

  function counterDown() {
    personas > 1 && setPersonas(personas - 1)
  }

  useEffect(() => {
    if (availability && personas > availability) setPersonas(availability)
  }, [availability])

  function onTourChange(value) {
    setTour(JSON.parse(value))
  }

  const addPointsToNumber = number => {
    let originalString = number.toString()
    let newString = ""
    for (let i = originalString.length - 1; i >= 0; i--) {
      newString = originalString[i] + newString
      if (!((originalString.length - i) % 3) && i !== 0)
        newString = "." + newString
    }
    return newString
  }

  return (
    <Fragment>
      <SEO title="Quilmes Tour" />
      <LayoutTour location={location} bases={bases}>
        <Router basepath="/lacasadequilmes">
          <QuilmesTourIndex
            exact
            path="/"
            images={images}
            location={location}
            setFecha={setFecha}
            setTour={setTour}
            setPersonas={setPersonas}
            personas={personas}
            fecha={fecha}
            tour={tour}
            tourOptions={tourOptions}
            dateOptions={dateOptions}
            dateOptionsOfCurrentTour={dateOptionsOfCurrentTour}
            onDateChange={onDateChange}
            onTourChange={onTourChange}
            availability={availability}
            counterUp={counterUp}
            counterDown={counterDown}
            questions={questions}
            comollego={comollego}
            precauciones={precauciones}
          />
          {/*<TourMobileDescription*/}
          {/*  exact*/}
          {/*  path="/tourdescription"*/}
          {/*  images={images.imgsTourCompleto}*/}
          {/*  dateOptions={dateOptions}*/}
          {/*  tourOptions={tourOptions}*/}
          {/*/>*/}
          {/*<ReservaStep1*/}
          {/*  exact*/}
          {/*  path="/reserva"*/}
          {/*  location={location}*/}
          {/*  fecha={fecha}*/}
          {/*  tour={tour}*/}
          {/*  personas={personas}*/}
          {/*  setPersonas={setPersonas}*/}
          {/*  setFecha={setFecha}*/}
          {/*  setTour={setTour}*/}
          {/*  setAvailability={setAvailability}*/}
          {/*  onDateChange={onDateChange}*/}
          {/*  onTourChange={onTourChange}*/}
          {/*  tourOptions={tourOptions}*/}
          {/*  dateOptionsOfCurrentTour={dateOptionsOfCurrentTour}*/}
          {/*  counterUp={counterUp}*/}
          {/*  counterDown={counterDown}*/}
          {/*  availability={availability}*/}
          {/*  selectedTime={selectedTime}*/}
          {/*  setSelectedTime={setSelectedTime}*/}
          {/*  addPointsToNumber={addPointsToNumber}*/}
          {/*/>*/}
          {/*<ReservaStep2*/}
          {/*  bases={bases}*/}
          {/*  exact*/}
          {/*  path="/reserva/step2"*/}
          {/*  location={location}*/}
          {/*  personas={personas}*/}
          {/*  fecha={fecha}*/}
          {/*  tour={tour}*/}
          {/*  selectedTime={selectedTime}*/}
          {/*  addPointsToNumber={addPointsToNumber}*/}
          {/*/>*/}
          {/*<ReservaStep3 exact path="/thanks" location={location} />*/}
        </Router>
      </LayoutTour>
    </Fragment>
  )
}

export default LaCasaDeQuilmes

export const data = graphql`
  query QuilmesTour {
    allBases {
      nodes {
        bases {
          fileName
          fileUrl
        }
        tyc {
          fileName
          fileUrl
        }
        politica {
          fileName
          fileUrl
        }
        id
      }
    }
    allPrecauciones {
      nodes {
        id
        title
        items {
          item
        }
      }
    }
    allComollego {
      nodes {
        id
        iframe
        subtitle
        title
        googleMaps
        text
        colectivos {
          colectivo
          link
        }
        trenes {
          tren
          trenlink
        }
      }
    }
    allFaq {
      nodes {
        answer
        id
        index
        question
      }
    }
    imgsTour: allFile(
      filter: { relativeDirectory: { eq: "lacasadequilmes" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    imgsHome: allFile(
      filter: { relativeDirectory: { eq: "lacasadequilmes/home" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    imgsLele: allFile(
      filter: { relativeDirectory: { eq: "lacasadequilmes/lele" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    imgsComoLlegar: allFile(
      filter: { relativeDirectory: { eq: "lacasadequilmes/comoLlegar" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    imgsCompartiExp: allFile(
      filter: { relativeDirectory: { eq: "lacasadequilmes/compartiExp" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    imgsTourCompleto: allFile(
      filter: { relativeDirectory: { eq: "lacasadequilmes/tourCompleto" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
