import React from "react"
// import Img from "gatsby-image"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/quilmesTourStyles/comoLlegar.module.css"
import BackgroundImage from "gatsby-background-image"

const ComoLlegar = ({ images, comollego, precauciones }) => {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })

  // console.log("dataPrecauciones", comollego)
  return (
    <>
      {!mobile ? (
        <BackgroundImage
          fluid={images["como llegar bg desk"].childImageSharp.fluid}
          className={styles.top1}
        >
          <BackgroundImage
            fluid={images["Rectangle 22"].childImageSharp.fluid}
            className={styles.rectangle}
          >
            <div className={styles.firstContainer} id="covid">
              <div className={styles.covidContextContainer}>
                <div className={styles.titleCovidContexContainer}>
                  {precauciones.map((covid, id) => {
                    return (
                      <h1 className={styles.titleCovidContext}>
                        {covid.title ?? ""}
                      </h1>
                    )
                  })}
                </div>
                <div className={styles.pointsCovidContexContainer}>
                  <ul>
                    {precauciones[0].items.map((covid, i) => {
                      return (
                        covid.item && (
                          <li
                            style={{ color: "rgba(240, 179, 69, 1)" }}
                            key={i}
                          >
                            <h1 className={styles.pointsCovidContext}>
                              {covid.item}
                            </h1>
                          </li>
                        )
                      )
                    })}
                  </ul>
                </div>
              </div>

              <div className={styles.comoLlegarContainer}>
                <h1 className={styles.comoLlegarTitle}>CÓMO LLEGAR</h1>
              </div>
            </div>
          </BackgroundImage>
          <div className={styles.blueBar}></div>
          <div className={styles.secondContainer}>
            <iframe
              className={styles.googleMapsIframe}
              title=""
              loading="lazy"
              allowfullscreen
              src={comollego[0].iframe}
            ></iframe>
            <div className={styles.textSecondContainer}>
              <div className={styles.textInfoContainer}>
                <div>
                  <h1 className={styles.mediosDeTransporte}>
                    MEDIOS DE TRANSPORTE
                  </h1>
                </div>
                <div>
                  <h1 className={styles.deskDescription}>
                    Estos son los medios de transporte que pasan cerca de
                    nuestra casa:
                    <br></br>- Colectivo:{" "}
                    {comollego[0].colectivos.map((col, i) => {
                      return (
                        <>
                          <a
                            key={i}
                            target="_blank"
                            href={col.link}
                            className={styles.transportationLink}
                          >
                            {col.colectivo}
                          </a>
                          ,{" "}
                        </>
                      )
                    })}
                    <br></br>- Tren:{" "}
                    {comollego[0].trenes.map((tren, i) => {
                      return (
                        <>
                          <a
                            key={i}
                            target="_blank"
                            href={tren.trenlink}
                            className={styles.transportationLink}
                          >
                            {tren.tren}
                          </a>
                          ,{" "}
                        </>
                      )
                    })}
                    La dirección es: Av 12 de Octubre y Gran Canaria.
                  </h1>
                </div>
                <div>
                  <h1 className={styles.puntoDeEncuentro}>
                    {comollego[0].text}
                  </h1>
                </div>
                <div className={styles.buttonContainer}>
                  <a className={styles.button} href={comollego[0].googleMaps}>
                    GOOGLE MAPS
                  </a>
                </div>
                <div className={styles.cabifyContainer}>
                  <div className={styles.titleContainer}>
                    <h1 className={styles.cabify}>{comollego[0].title}</h1>
                  </div>
                  <div className={styles.descriptionContainer1}>
                    <h1 className={styles.description}>
                      {comollego[0].subtitle}
                    </h1>
                    <h1 className={styles.description2}>
                      {/* 25% de Descuento CODIGO TOURQUILMES */}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      ) : (
        <BackgroundImage
          fluid={images["comoLlegarBg"].childImageSharp.fluid}
          className={styles.top1}
        >
          <div className={styles.mainContainer}>
            <div className={styles.textContainer}>
              <h1 className={styles.titulo}>CÓMO LLEGAR</h1>
              <div className={styles.blueBar}></div>
            </div>
            <iframe
              className={styles.googleMapsIframe}
              loading="lazy"
              allowfullscreen
              src={comollego[0].iframe}
            ></iframe>
            <div className={styles.textContainer2}>
              <h1 className={styles.titulo2}>MEDIOS DE TRANSPORTE</h1>
            </div>
            <div className={styles.textContainer3}>
              <h1 className={styles.deskDescription}>
                Estos son los medios de transporte que pasan cerca de nuestra
                casa:
                <br></br>- Colectivo:{" "}
                {comollego[0].colectivos.map((col, i) => {
                  return (
                    <>
                      <a
                        key={i}
                        target="_blank"
                        href={col.link}
                        className={styles.transportationLink}
                      >
                        {col.colectivo}
                      </a>
                      ,{" "}
                    </>
                  )
                })}
                <br></br>- Tren:{" "}
                {comollego[0].trenes.map((tren, i) => {
                  return (
                    <>
                      <a
                        key={i}
                        target="_blank"
                        href={tren.trenlink}
                        className={styles.transportationLink}
                      >
                        {tren.tren}
                      </a>
                      ,{" "}
                    </>
                  )
                })}
                La dirección es: Av 12 de Octubre y Gran Canaria.
              </h1>
            </div>
            <div>
              <h1 className={styles.puntoDeEncuentro}>{comollego[0].text}</h1>
            </div>
            <div className={styles.buttonContainer}>
              <a className={styles.button} href={comollego[0].googleMaps}>
                GOOGLE MAPS
              </a>
            </div>
          </div>
          <div className={styles.cabifyContainer}>
            <div className={styles.titleContainer}>
              <h1 className={styles.cabify}>{comollego[0].title}</h1>
            </div>
            <div className={styles.descriptionContainer1}>
              <h1 className={styles.description}>{comollego[0].subtitle}</h1>
              <h1 className={styles.description2}>
                {/* 25% de Descuento CODIGO TOURQUILMES */}
              </h1>
            </div>
          </div>
        </BackgroundImage>
      )}
    </>
  )
}

export default ComoLlegar
