export const cleanImagesArray = data => {
  const keys = Object.keys(data)
  const Images = {}
  keys.forEach(key => {
    data[key].edges &&
      data[key].edges.forEach(img => {
        Images[key] = { ...Images[key], [img.node.name]: img.node }
      })
  })
  return Images
}
