import React from "react"
import { Link } from "gatsby"
import styles from "../styles/sideMenu.module.css"
import FlechaSelected from "../images/logos/flechaSelected.svg"
import logoQ from "../images/logos/quilmesLogo.svg"
import logoIg from "../images/logos/logoIg.svg"
import logoFb from "../images/logos/logoFb.svg"
import logoTw from "../images/logos/logoTw.svg"
import logoYou from "../images/logos/logoYou.svg"
import { AiOutlineClose } from "react-icons/ai"
import logo360 from "../images/logos/360.svg"

const menuData = [
  {
    title: "INICIO",
    path: "",
    icon: "",
  },
  {
    title: "NUESTRA HISTORIA",
    path: "history",
    icon: "",
  },
  {
    title: "NUESTRAS VARIEDADES",
    path: "#varieties",
    icon: "",
  },

  {
    title: "NUESTROS LUGARES",
    path: "places",
    icon: "",
  },
  // {
  //   title: "LA CERVECERÍA",
  //   path: "#",
  //   icon: "",
  // },
  {
    title: "MERCHANDISING",
    path: "#",
    icon: "",
    link: "https://www.tada.com.ar/catalogo/grupo/quilmes",
  },
  {
    title: "BALÓN DE POTRERO",
    path: "balondepotrero",
    icon: "",
    // link: "https://elclubdelhincha.com.ar"
  },
  {
    title: "LA CASA DE QUILMES",
    path: "lacasadequilmes",
    icon: "",
  },

]


function SideMenu({ setActiveMenu, activeMenu }) {
  // console.log("activeMenu", activeMenu)

  return (
    <div className={!activeMenu ? styles.containerActive : styles.container}>
      <div className={styles.logoContainer}>
        <img alt="logo Quilmes" className={styles.logoQ} src={logoQ} />
        <AiOutlineClose
          onClick={() => setActiveMenu(!activeMenu)}
          className={styles.close}
        ></AiOutlineClose>
      </div>
      <div className={styles.options}>
        {menuData.map(item => {
          return item.link ? (
            <a
              key={item.title}
              className={styles.link}
              href={item.link}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "Menu",
                  event_action: "Click",
                  event_label: `${item.link}`,
                  interaction: true,
                  component_name: "",
                  element_text: "",
                })
              }}
            >
              <img
                alt=""
                className={styles.flechaSelected}
                src={FlechaSelected}
              />
              {item.icon !== "" ? (
                <img
                  alt="logo vista 360 grados"
                  className={styles.logo360}
                  src={logo360}
                />
              ) : null}
              <span className={styles.title}>{item.title}</span>
            </a>
          ) : (
            <Link
              key={item.title}
              className={styles.link}
              to={`/${item.path}`}
              onClick={() => {
                setActiveMenu(!activeMenu)
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "Menu",
                  event_action: "Click",
                  event_label: `${window.location.origin + "/" + item.path}`,
                  interaction: true,
                  component_name: "",
                  element_text: "",
                })
              }}
            >
              <img
                alt=""
                className={styles.flechaSelected}
                src={FlechaSelected}
              />
              {item.icon !== "" ? (
                <img
                  alt="logo vista 360 grados"
                  className={styles.logo360}
                  src={logo360}
                />
              ) : null}
              <span className={styles.title}>{item.title}</span>
            </Link>
          )
        })}
      </div>
      <div className={styles.socialIcons}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/quilmescerveza/?ref=bookmarks"
          onClick={() => {
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Social",
              event_action: "Click",
              event_label: `https://www.facebook.com/quilmescerveza/?ref=bookmarks`,
              interaction: true,
              component_name: "boton_facebook",
              element_text: "Facebook",
            })
          }}
        >
          <img alt="logo facebook" src={logoFb} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/Quilmes_Cerveza"
          onClick={() => {
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Social",
              event_action: "Click",
              event_label: `https://twitter.com/Quilmes_Cerveza`,
              interaction: true,
              component_name: "boton_twitter",
              element_text: "Twitter",
            })
          }}
        >
          <img alt="logo twitter" src={logoTw} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/cervezaquilmes/?hl=es-la"
          onClick={() => {
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Social",
              event_action: "Click",
              event_label: `https://www.instagram.com/cervezaquilmes/?hl=es-la`,
              interaction: true,
              component_name: "boton_instagram",
              element_text: "Instagram",
            })
          }}
        >
          <img alt="logo instagram" src={logoIg} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/user/Quilmes120Argentina"
          onClick={() => {
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Social",
              event_action: "Click",
              event_label: `https://www.youtube.com/user/Quilmes120Argentina`,
              interaction: true,
              component_name: "boton_youtube",
              element_text: "Youtube",
            })
          }}
        >
          <img alt="logo youtube" src={logoYou} />
        </a>
      </div>
    </div>
  )
}

export default SideMenu
