import React, { useState, useEffect } from "react"
import HomeTour from "./home"
import TourCompleto from "./tourCompleto"
import LeleCristobal from "./leleCristobal"
import ComoLlegar from "./comoLlegar"
import PreguntasFrecuentes from "./preguntasFrecuentes"
import CompartiExp from "./compartiExp"

const QuilmesTourIndex = ({
  images,
  setFecha,
  setTour,
  setPersonas,
  personas,
  fecha,
  tour,
  tourOptions,
  dateOptions,
  dateOptionsOfCurrentTour,
  onDateChange,
  onTourChange,
  availability,
  counterUp,
  counterDown,
  questions,
  comollego,
  precauciones,

}) => {
  return (
    <>
      {images && (
        <>
          <HomeTour
            images={images.imgsHome}
            setFecha={setFecha}
            setTour={setTour}
            setPersonas={setPersonas}
            personas={personas}
            fecha={fecha}
            tour={tour}
            tourOptions={tourOptions}
            dateOptionsOfCurrentTour={dateOptionsOfCurrentTour}
            onDateChange={onDateChange}
            onTourChange={onTourChange}
            availability={availability}
            counterUp={counterUp}
            counterDown={counterDown}
          />
          {/*<div id="tour">*/}
          {/*  <TourCompleto*/}
          {/*    images={images.imgsTourCompleto}*/}
          {/*    fecha={fecha}*/}
          {/*    setFecha={setFecha}*/}
          {/*    setTour={setTour}*/}
          {/*    setPersonas={setPersonas}*/}
          {/*    personas={personas}*/}
          {/*    tourOptions={tourOptions}*/}
          {/*    dateOptions={dateOptions}*/}
          {/*    counterUp={counterUp}*/}
          {/*    counterDown={counterDown}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*/!* <LeleCristobal images={images.imgsLele} /> *!/*/}
          {/*<ComoLlegar*/}

          {/*  images={images.imgsComoLlegar}*/}
          {/*  comollego={comollego}*/}
          {/*  precauciones={precauciones}*/}
          {/*/>*/}
          {/*<PreguntasFrecuentes questions={questions} />*/}
          {/*<CompartiExp images={images.imgsCompartiExp} />*/}
        </>
      )}
    </>
  )
}

export default QuilmesTourIndex
