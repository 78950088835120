import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/quilmesTourStyles/reservaStep2.module.css"
import { Link } from "@reach/router"
import { Collapse, Input, Checkbox } from "antd"
import "antd/dist/antd.css"
import "../../styles/quilmesTourStyles/index.css"
import short from "short-uuid"
import { navigate } from "@reach/router"
import { Loader } from "../Loader"
const { Panel } = Collapse

const CompletaTusDatos = ({
  bases,
  tour,
  personas,
  fecha,
  selectedTime,
  discount,
  couponId,
}) => {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  const [value, setValue] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [acepto, setAcepto] = useState(false)
  const [error, setError] = useState({})
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false)

  const checkWhetherAllFieldsAreComplete = () => {
    const objectValuesArray = Object.values(value)
    const emailsArray = objectValuesArray.map(person => person.email)
    if (
      objectValuesArray.length === personas &&
      !objectValuesArray
        .map(person =>
          Boolean(
            person.nombre &&
              person.edad &&
              person.email &&
              person.telefono &&
              person.DNI
          )
        )
        .includes(false) &&
      new Set(emailsArray).size === emailsArray.length
    )
      return true
    else return false
  }

  const onFocus = (index, e) => {
    const { name } = e.target
    setError({
      ...error,
      [index]: {
        ...error[index],
        [name]: { error: false },
      },
    })
  }

  const onBlur = (value, index, key) => {
    if (key === "email") {
      let email
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (value[index]?.hasOwnProperty("email")) {
        email = value[index]["email"]
      }
      if (email) {
        const emailValid = email.match(mailformat)
        if (emailValid === null) {
          setError({
            ...error,
            [index]: {
              ...error[index],
              [key]: { error: true, message: "Ingrese un correo por favor" },
            },
          })
        } else {
          let valuesArray = Object.values(value)
          const repeatedEmail = valuesArray.some(
            (person, someIndex) => person.email === email && index !== someIndex
          )
          if (repeatedEmail) {
            setError({
              ...error,
              [index]: {
                ...error[index],
                [key]: {
                  error: true,
                  message:
                    "Por favor ingrese el correo de cada participante para que cada uno reciba su entrada",
                },
              },
            })
          }
        }
      } else {
        setError({
          ...error,
          [index]: {
            ...error[index],
            [key]: { error: true, message: "Ingrese un correo por favor" },
          },
        })
      }
    } else {
      let inputValue
      if (value[index]?.hasOwnProperty(key)) {
        inputValue = value[index][key]
      }
      if (inputValue) {
        setError({
          ...error,
          [index]: {
            ...error[index],
            [key]: { error: false },
          },
        })
      } else {
        setError({
          ...error,
          [index]: {
            ...error[index],
            [key]: {
              error: true,
              message:
                key === "edad"
                  ? "Ingrese una " + key + " por favor"
                  : "Ingrese un " + key + " por favor",
            },
          },
        })
      }
    }
  }

  const handleChange = (e, index) => {
    const key = e.target.name
    const inputValue = e.target.value
    const checked = e.target.checked
    setValue({
      ...value,
      [index]: {
        ...value[index],
        [key]: inputValue,
        conductor: checked,
      },
    })
  }

  const converter = obj => {
    const newObj = {
      nombre: [],
      email: [],
      edad: [],
      conductor: [],
      principal: [],
      disponibilidad: selectedTime.disponibilidad,
      codigo: [],
      telefono: [],
      DNI: [],
      guests: [],
      precio: tour.precio * ((100 - discount) / 100),
      descuento: discount,
      tour: tour.name,
      couponId,
    }
    for (const key in obj) {
      if (obj[key]["nombre"]) newObj["nombre"].push(obj[key].nombre)
      if (obj[key]["email"]) newObj["email"].push(obj[key].email)
      if (obj[key]["edad"]) newObj["edad"].push(obj[key].edad)
      newObj["conductor"].push(obj[key].conductor)
      newObj["codigo"].push(short.generate())
      newObj["telefono"].push(obj[key].telefono)
      newObj["DNI"].push(obj[key].DNI)
      if (key === "0") newObj["principal"].push(true)
      else newObj["principal"].push(false)
      newObj["guests"].push({
        nombre: obj[key]["nombre"],
        email: obj[key]["email"],
        edad: obj[key]["edad"],
        principal: key === "0" ? true : false,
        conductor: obj[key]["conductor"],
        telefono: obj[key]["telefono"],
        DNI: obj[key]["DNI"],
      })
    }
    return newObj
  }

  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"
  // const api = "https://wacopaamericamotmback-prd-stage.azurewebsites.net/api"
  // const api = "http://localhost:8080/api"

  const handleSuccess = async () => {
    const convertedObject = converter(value)
    if (!formHasBeenSubmitted) {
      setIsLoading(true)
      setFormHasBeenSubmitted(true)
      fetch(`${api}/tourtest/createpreference/${selectedTime.idFecha}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(convertedObject),
      })
        .then(async response => {
          if (response.ok) {
            let data = await response.json()
            const { id, link } = data
            localStorage.setItem(
              "pendingReservation",
              JSON.stringify({
                id,
                email: convertedObject.email,
                nombre: convertedObject.nombre,
                codigo: convertedObject.codigo,
                telefono: convertedObject.telefono,
                DNI: convertedObject.DNI,
                tour: tour.name,
                fecha,
                horario: selectedTime.horaInicio,
                guests: convertedObject.guests,
              })
            )
            if (link) {
              setIsLoading(false)
              window.location.href = link
            } else {
              navigate("/lacasadequilmes/thanks?success=true")
            }
          } else {
            throw new Error(response)
          }
        })
        .catch(error => console.error("Error cargando los datos. \n", error))
    }
  }

  return (
    <div className={styles.completaTusDatos}>
      {!isLoading ? (
        <>
          <div className={styles.datosTitleContainer}>
            <h1 className={styles.datosTitle}>Completá con tus datos</h1>
          </div>
          <Collapse
            expandIconPosition={"right"}
            style={{
              background: "rgba(15, 145, 215, 0.04)",
              fontFamily: "Lato-Regular",
              fontSize: "14px",
              border: "1px solid rgba(15, 145, 215, 0.08)",
              borderRadius: mobile ? "0px" : "12px",
            }}
            className="collapseText"
            defaultActiveKey={["0"]}
          >
            {[...Array(personas)].map((persona, i) => {
              return (
                <Panel
                  header={`PARTICIPANTE ${i + 1}`}
                  key={i}
                  className="collapsePanel"
                  style={{
                    border: "none",
                    borderRadius: mobile ? "0px" : "12px",
                  }}
                >
                  <div>
                    <div>
                      <h1 className={styles.collapseNameText}>
                        Nombre y apellido
                      </h1>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: mobile && "column",
                        }}
                      >
                        <Input
                          style={{
                            borderRadius: mobile ? "0px" : "40px",
                            border: "1px solid rgba(15, 145, 215, 0.08)",
                            height: mobile && "20%",
                            width: mobile ? "100%" : "40%",
                            background: "rgba(15, 145, 215, 0.06)",
                            marginBottom: "15px",
                          }}
                          name="nombre"
                          onChange={e => handleChange(e, i)}
                          value={(value[i] && value[i]["nombre"]) || ""}
                          onFocus={e => onFocus(i, e)}
                          onBlur={() => onBlur(value, i, "nombre")}
                        />
                        <span
                          className={
                            error[i] &&
                            error[i].nombre &&
                            error[i].nombre.error === true
                              ? styles.error
                              : styles.none
                          }
                        >
                          {error[i] &&
                            error[i].nombre &&
                            error[i].nombre.message}
                        </span>

                        <div
                          style={{
                            marginLeft: mobile ? "0px" : "25px",
                            marginBottom: "18px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            name="conductor"
                            checked={value[i] && value[i]["conductor"]}
                            onChange={e => handleChange(e, i)}
                          ></Checkbox>
                          <span
                            style={{
                              textDecoration: "underline rgba(15, 145, 215, 1)",
                              color: "rgba(15, 145, 215, 1)",
                              marginLeft: "7px",
                              marginBottom: "2px",
                            }}
                          >
                            ¿Eres el conductor designado?
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h1 className={styles.collapseNameText}>Email</h1>
                      <Input
                        style={{
                          borderRadius: mobile ? "0px" : "40px",
                          border: "1px solid rgba(15, 145, 215, 0.08)",
                          height: mobile && "20%",
                          width: mobile ? "100%" : "40%",
                          background: "rgba(15, 145, 215, 0.06)",
                          marginBottom: "15px",
                        }}
                        name="email"
                        onChange={e => handleChange(e, i)}
                        value={(value[i] && value[i]["email"]) || ""}
                        onFocus={e => onFocus(i, e)}
                        onBlur={() => onBlur(value, i, "email")}
                      />
                      <span
                        className={
                          error[i] &&
                          error[i].email &&
                          error[i].email.error === true
                            ? styles.error
                            : styles.none
                        }
                      >
                        {error[i] && error[i].email && error[i].email.message}
                      </span>
                    </div>
                    <div>
                      <h1 className={styles.collapseNameText}>Edad</h1>
                      <Input
                        style={{
                          borderRadius: mobile ? "0px" : "40px",
                          border: "1px solid rgba(15, 145, 215, 0.08)",
                          height: mobile && "20%",
                          width: mobile ? "100%" : "20%",
                          background: "rgba(15, 145, 215, 0.06)",
                          marginBottom: "8px",
                        }}
                        name="edad"
                        onChange={e => handleChange(e, i)}
                        value={value[i] && value[i]["edad"]}
                        type="number"
                        onFocus={e => onFocus(i, e)}
                        onBlur={() => onBlur(value, i, "edad")}
                      />
                      <span
                        className={
                          error[i] &&
                          error[i].edad &&
                          error[i].edad.error === true
                            ? styles.error
                            : styles.none
                        }
                      >
                        {error[i] && error[i].edad && error[i].edad.message}
                      </span>
                    </div>
                    <div>
                      <h1 className={styles.collapseNameText}>Teléfono</h1>
                      <Input
                        style={{
                          borderRadius: mobile ? "0px" : "40px",
                          border: "1px solid rgba(15, 145, 215, 0.08)",
                          height: mobile && "20%",
                          width: mobile ? "100%" : "20%",
                          background: "rgba(15, 145, 215, 0.06)",
                          marginBottom: "8px",
                        }}
                        name="telefono"
                        onChange={e => handleChange(e, i)}
                        value={value[i] && value[i]["telefono"]}
                        onFocus={e => onFocus(i, e)}
                        onBlur={() => onBlur(value, i, "telefono")}
                      />
                      <span
                        className={
                          error[i] &&
                          error[i].telefono &&
                          error[i].telefono.error === true
                            ? styles.error
                            : styles.none
                        }
                      >
                        {error[i] &&
                          error[i].telefono &&
                          error[i].telefono.message}
                      </span>
                    </div>
                    <div>
                      <h1 className={styles.collapseNameText}>DNI</h1>
                      <Input
                        style={{
                          borderRadius: mobile ? "0px" : "40px",
                          border: "1px solid rgba(15, 145, 215, 0.08)",
                          height: mobile && "20%",
                          width: mobile ? "100%" : "20%",
                          background: "rgba(15, 145, 215, 0.06)",
                          marginBottom: "8px",
                        }}
                        name="DNI"
                        onChange={e => handleChange(e, i)}
                        value={value[i] && value[i]["DNI"]}
                        onFocus={e => onFocus(i, e)}
                        onBlur={() => onBlur(value, i, "DNI")}
                      />
                      <span
                        className={
                          error[i] &&
                          error[i].DNI &&
                          error[i].DNI.error === true
                            ? styles.error
                            : styles.none
                        }
                      >
                        {error[i] && error[i].DNI && error[i].DNI.message}
                      </span>
                    </div>
                  </div>
                </Panel>
              )
            })}
          </Collapse>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Checkbox onChange={() => setAcepto(!acepto)}></Checkbox>
            <span
              style={{
                marginLeft: "7px",
                fontFamily: "Lato-Regular",
                marginTop: "4px",
                marginBottom: "15px",
                lineHeight: "14px",
              }}
            >
              He leído y acepto{" "}
              <a
                target="_blank"
                href={bases.bases.filreUrl}
                style={{
                  textDecoration: "underline",
                  fontFamily: "Lato-Regular",
                  color: "black",
                }}
              >
                Bases y condiciones
              </a>
              ,{" "}
              <a
                target="_blank"
                href={bases.tyc.filreUrl}
                style={{
                  textDecoration: "underline",
                  fontFamily: "Lato-Regular",
                  color: "black",
                }}
              >
                Términos y condiciones
              </a>
              ,{" "}
              <a
                target="_blank"
                href={bases.politica.filreUrl}
                style={{
                  textDecoration: "underline",
                  fontFamily: "Lato-Regular",
                  color: "black",
                }}
              >
                Políticas de privacidad
              </a>{" "}
              y activaciones de marketing.
            </span>
          </div>
          <div className={styles.buttonContainer}>
            <div className={mobile ? styles.buttonReservaVolver : styles.none}>
              <Link to="/lacasadequilmes/reserva" activeClassName="selected">
                <h1 className={styles.buttonText}>Volver</h1>
              </Link>
            </div>

            {acepto && checkWhetherAllFieldsAreComplete() ? (
              <div
                className={styles.buttonReserva}
                onClick={() => handleSuccess()}
              >
                <h1 className={styles.buttonText}>
                  {discount === 100 ? "Confirmar" : "Continuar"}
                </h1>
              </div>
            ) : (
              <div
                className={`${styles.buttonReserva} ${styles.buttonReservaDeshabilitado}`}
              >
                <h1 className={styles.buttonText}>
                  {discount === 100 ? "Confirmar" : "Continuar"}
                </h1>
              </div>
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}
export default CompletaTusDatos
