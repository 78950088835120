import React, { useState } from "react"
import { Collapse } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/quilmesTourStyles/preguntasFrecuentes.module.css"

const { Panel } = Collapse

const PreguntasFrecuentes = ({ questions }) => {
  const [lastVisibleDesktopQuestion, setLastVisibleDesktopQuestion] = useState(
    6
  )
  const [lastVisibleMobileQuestion, setLastVisibleMobileQuestion] = useState(3)

  const mobile = useMediaQuery({ query: "(max-width: 485px)" })

  const seeAllQuestions = () => {
    if (mobile) {
      setLastVisibleMobileQuestion(26)
    } else {
      setLastVisibleDesktopQuestion(26)
    }
  }


  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>PREGUNTAS FRECUENTES</h1>
          <div className={styles.blueBar}></div>
        </div>
        <div className={styles.collapseContainer}>
          <Collapse
            expandIconPosition={"right"}
            style={{
              border: "none",
              background: "rgba(253, 253, 253, 0)",
              width: mobile ? "90%" : "70%",
              fontFamily: "Tungsten-Medium",
              fontSize: mobile ? "23px" : "30px",
              textTransform: "uppercase",
              marginRight: !mobile && "50px",
            }}
            expandIcon={() => <DownOutlined />}
            onChange={e => {
              if (e.length > 0) {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "FAQ",
                  event_action: `Expand`,
                  event_label: `${
                    questions.sort((a, b) => a.index - b.index)[e[e.length - 1]]
                      .question
                  }`,
                  component_name: "boton_ver_mas",
                })
              }
            }}
          >
            {mobile
              ? questions
                  .sort((a, b) => a.index - b.index)
                  .map(
                    (item, index) =>
                      index < lastVisibleMobileQuestion && (
                        <Panel header={item.question} key={index}>
                          <div> {item.answer} </div>
                        </Panel>
                      )
                  )
              : questions
                  .sort((a, b) => a.index - b.index)
                  .map(
                    (item, index) =>
                      !(index % 2) &&
                      index < lastVisibleDesktopQuestion && (
                        <Panel header={item.question} key={index}>
                          <div> {item.answer} </div>
                        </Panel>
                      )
                  )}
          </Collapse>
          <Collapse
            expandIconPosition={"right"}
            style={{
              border: "none",
              background: "rgba(253, 253, 253, 0)",
              width: "70%",
              fontFamily: "Tungsten-Medium",
              textTransform: "uppercase",
              fontSize: "30px",
              display: mobile && "none",
            }}
            expandIcon={() => <DownOutlined />}
            onChange={e => {
              if (e.length > 0) {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "FAQ",
                  event_action: `Expand`,
                  event_label: `${
                    questions.sort((a, b) => a.index - b.index)[e[e.length - 1]]
                      .question
                  }`,
                  component_name: "boton_ver_mas",
                })
              }
            }}
          >
            {mobile
              ? null
              : questions
                  .sort((a, b) => a.index - b.index)
                  .map(
                    (item, index) =>
                      index % 2 &&
                      index < lastVisibleDesktopQuestion && (
                        <Panel header={item.question} key={index}>
                          {item.answer}
                        </Panel>
                      )
                  )}
          </Collapse>
        </div>
        {lastVisibleMobileQuestion < 26 && lastVisibleDesktopQuestion < 26 && (
          <div className={styles.verTodasContainer}>
            <h1 className={styles.verTodasText} onClick={seeAllQuestions}>
              Ver todas
            </h1>
          </div>
        )}
      </div>
    </>
  )
}

export default PreguntasFrecuentes
