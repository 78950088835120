import React, { useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/quilmesTourStyles/reservaStep3.module.css"
import { Link, useLocation } from "@reach/router"
import queryString from "query-string"

import "antd/dist/antd.css"
import "../../styles/quilmesTourStyles/index.css"

const Reserva3 = () => {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })

  const location = useLocation()

  const query = queryString.parse(location.search)

  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"
  // const api = "https://wacopaamericamotmback-prd-stage.azurewebsites.net/api"
  // const api = "http://localhost:8080/api"

  useEffect(() => {
    if (query.success) {
      let pendingReservation = JSON.parse(
        localStorage.getItem("pendingReservation")
      )
      if (pendingReservation)
        fetch(`${api}/tour/reservation/${pendingReservation.id}/confirm`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pendingReservation),
        })
          .then(async response => {
            if (response.ok) {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Reservation",
                event_action: `Success`,
                // event_label: `${tour.name}`,
                interaction: true,
                component_name: "boton_reservar",
                element_text: "Reservar",
              })
            }
            if (!response.ok) {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Reservation",
                event_action: `Error`,
                // event_label: `${tour.name}`,
                interaction: true,
                component_name: "boton_reservar",
                element_text: "Reservar",
              })
              throw new Error(response)
            }
          })
          .catch(error => console.error("Error cargando los datos. \n", error))
    }
  }, [])

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}></div>
      <div className={styles.pagoExitosoContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.pagoExitosoText}>TU PAGO FUE EXITOSO</h1>
          <div className={styles.subTitleContainer}>
            <h1 className={styles.pagoExitosoSubText}>
              Te vamos a enviar una confirmación por correo electrónico con el
              detalle de tu compra ¡Nos vemos pronto en el tour!
            </h1>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonReserva}>
            <Link to="/lacasadequilmes/" activeClassName="selected">
              <h1 className={styles.buttonText}>Ir al Inicio</h1>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reserva3
